import logo from './logo.svg';
import Form from './components/Form/Form'
import FormTest from "./components/FormTest/FormTest"
import "./App.scss"
import { useEffect, useState } from 'react';

function App() {

  const InitModal = () => {
    const [showInitModal, setShowInitModal] = useState(false);
  
    useEffect(() => {
      // Trigger modal on component mount
      setShowInitModal(true);
    }, []); // Empty dependency array ensures this effect runs only once on component mount
  
    const closeInitModal = () => {
      setShowInitModal(false);
    };
  
    return (
      <div className={`init-modal ${showInitModal ? 'is-active' : ''}`}>
        <div className="init-modal-background" onClick={closeInitModal}></div>
        <div className="modal-content">
          <div className="box">
          <span className="init-modal-close" onClick={closeInitModal}>&times;</span> {/* 'X' to close modal */}
            <h3>The information in this directory is updated regularly, but it can change at any time. Please contact the provider to find out if they are accepting new patients.<br></br> Thank you <br></br>
              Click <a target="_blank" href='/centers.pdf'>here</a> for important information regarding the Change Healthcare cyber-attack.</h3>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="App">
      <FormTest />
      <InitModal />
    </div>
  );
}

export default App;
