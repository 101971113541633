import React, { useEffect, useState, useRef } from 'react';
import Results from './Results';
import './form.scss';

const FormTest = () => {

    const [apiDone, setApiDone] = useState(false);
    const [loading, setLoading] = useState(true);
    const [secondPage, setSecondPage] = useState(false);
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState({ type: [], specialty: [], sub_specialty: [], plan: [] });
    const [searchParams, setSearchParams] = useState({
        'provider_plan_name': '',
        'type': '',
        'specialty': '',
        'sub_specialty': '',
        'provider': '',
        'language': '',
        'within': 15,
        'zip': '',
        'phone-number': '',
        'phone': '',
        'page': 1
    });





    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSearchParams((prevParams) => ({
            ...prevParams,
            [name]: value,
        }));
    };

    useEffect(() => {
        if (searchParams.page !== 1 || secondPage){
            handleSubmit()
        }

        const fetchData = async () => {

            const nonEmptyParams = Object.entries(searchParams).reduce((acc, [key, value]) => {
                if (value !== '' || value == "Any") {
                    acc[key] = value;
                }
                return acc;
            }, {});


            const queryString = new URLSearchParams(nonEmptyParams).toString();
            
            try {
                const response = await fetch(`https://api-cphl-providers.seniorsaas.com:8000/services/?${queryString}`);
                const json = await response.json();
                setInitialData(json);
            } catch (error) {
                console.error('Error fetching data:', error);
            } 
        };

        fetchData();
    }, [searchParams]);


    const handleSubmit = async (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }

        setApiDone(true)
        const nonEmptyParams = Object.entries(searchParams).reduce((acc, [key, value]) => {
        if (value !== '' || value === "Any") {
            acc[key] = value;
            } 
            return acc;
        }, {});


        const queryString = new URLSearchParams(nonEmptyParams).toString();

        try {
            const response = await fetch(`https://api-cphl-providers.seniorsaas.com:8000/providers/?${queryString}`, {
                method: 'GET',

            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const data = await response.json();
            setData(data);
        } catch (error) {
            console.error('Fetch operation error:', error);
        } finally {
            setLoading(false)
        }
    };

    const handleNext = () => {
        setSecondPage(true)
        setSearchParams((prevParams) => ({
            ...prevParams,
            page: searchParams.page+1,
        }));
        console.log(searchParams.page)
        
    }

    const handleFirst = () => {
        setSearchParams((prevParams) => ({
            ...prevParams,
            page: 1,
        }));
    }

    const handlePrev = () => {
        setSearchParams((prevParams) => ({
            ...prevParams,
            page: searchParams.page-1,
        }));
    }

    // console.log(Object.keys(initialData))
    //    if(initialData.plan[1] !== undefined){
    //       console.log(Object.keys(initialData.plan[1]))
    //    }

    return (
        <div className="form-container">
            <div className="head">
                <h2>Searchable Directory</h2>
                {/* <div class="language-links">
                    <a href="#spanish">Spanish</a> | <a href="#chinese">Chinese</a>
                </div> */}

            </div>

            <form onSubmit={handleSubmit} class="search-form">
                <div class="form-row">
                    <div class="form-group">
                        <label for="provider_plan_name">PLAN:</label>
                        <select onChange={handleInputChange} id="plan" name="provider_plan_name">
                            {
                                initialData.plan.map((plan) => (
                                    <option value={Object.values(plan)[0]}>{Object.keys(plan)[0]}</option>
                                ))
                            }

                        </select>
                    </div>
                    <div class="form-group">
                        <label for="type">TYPE:</label>
                        <select onChange={handleInputChange} id="type" name="type">

                            {
                                initialData.type.map((specialty) => (
                                    <option value={specialty}>{specialty}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group">
                        <label for="specialty">SPECIALTY:</label>
                        <select onChange={handleInputChange} id="specialty" name="specialty">

                            {
                                initialData.specialty.map((specialty) => (
                                    <option value={specialty}>{specialty}</option>
                                ))
                            }

                        </select>
                    </div>
                    <div class="form-group">
                        <label for="sub_specialty">SUB SPECIALTY:</label>
                        <select onChange={handleInputChange} id="sub-specialty" name="sub_specialty">

                            {
                                initialData.sub_specialty.map((specialty) => (
                                    <option value={specialty}>{specialty}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div class="form-row full-width">
                    <div class="form-group-full">
                        <label for="provider">PROVIDER:</label>
                        <input onChange={handleInputChange} type="text" id="provider" name="provider" placeholder="PROVIDER NAME" />
                    </div>

                </div>
                <div class="form-row">
                    <div class="form-group">
                        <label for="language">LANGUAGE:</label>
                        <input onChange={handleInputChange} type="text" id="language" name="language" placeholder="LANGUAGE" />
                    </div>
                    <div class="form-group">
                        <label for="within">WITHIN:</label>
                        <select onChange={handleInputChange} id="within" name="within">
                            <option value={1}>1 miles</option>
                            <option value={5}>5 miles</option>
                            <option value={10}>10 miles</option>
                            <option selected="selected" value={15}>15 miles</option>
                            <option value={20}>20 miles</option>
                        </select>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group">
                        <label for="zipcode">ZIPCODE:</label>
                        <input onChange={handleInputChange} type="text" id="zipcode" name="zip" placeholder="ENTER ZIP CODE" />
                    </div>
                    <div class="form-group">
                        <label for="phone">PHONE NUMBER:</label>
                        <input onChange={handleInputChange} type="text" id="phone" name="phone-number" placeholder="PHONE NUMBER" />
                    </div>
                </div>
                <div class="form-row">
                    <input type="submit" value="SEARCH" />
                </div>
            </form>
            {apiDone &&
                <Results loading={loading} data={data} handleNext={handleNext} handlePrev={handlePrev} handleFirst={handleFirst} page={searchParams.page} />
            }

        </div>
    );
};

export default FormTest;
