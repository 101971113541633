import React from 'react';
import './Key.scss';

// Placeholder Icon component - replace with your actual icon component
const Icon = ({ className }) => <div className={`icon ${className}`} />;

const Key = () => {
  return (
    <div className="key-section">
      <h2>Key</h2>
      <ul className="key-list">
        <div className='col'>
          <li className="key-item">
            <span class="fa-stack">
              <i class="fa fa-check fa-stack-1x fa-inverse"></i>
            </span>
            <span>Accepting New Patients</span>
          </li>
          <li className="key-item">
            <span class="fa-stack fida_ico">
              <font  id='ada' class="ico-font">ADA+</font>
              <i class="fa fa-square fa-stack-2x"></i>
            </span>
            <span>ADA+ Standards</span>
          </li>
          <li className="key-item">
            <span class="fa-stack">
              <i class="fa fa-square fa-stack-2x"></i>
              <i class="fa fa-globe fa-stack-1x fa-inverse"></i>
            </span>
            <span>Cultural Competence</span>
          </li>
          <li className="key-item">
            <span class="fa-stack fida_ico">
              <font class="ico-font-90">90</font>
              <i class="fa fa-square fa-stack-2x"></i>
            </span>
            <span>Accepts 90-day Prescriptions</span>
          </li>
          <li className="key-item">
            <span class="fa-stack">
              <i class="fa fa-square fa-stack-2x"></i>
              <i class="fa fa-house-medical-circle-check fa-stack-1x fa-inverse"></i>
            </span>
            <span>Accepts Medicaid</span>
          </li>
        </div>
        <div className='col'>
          <li className="key-item">
            <span class="fa-stack">
              <i class="fa fa-square fa-stack-2x"></i>
              <i class="fa fa-wheelchair fa-stack-1x fa-inverse"></i>
            </span>
            <span>Accessible</span>
          </li>
          <li className="key-item">
            <span class="fa-stack">
              <i class="fa fa-square fa-stack-2x"></i>
              <i class="fa fa-certificate fa-stack-1x fa-inverse"></i>
            </span>
            <span>Board Certified</span>
          </li>
          <li className="key-item">
            <span class="fa-stack">
              <font class="ico-font-e"></font>
              <i class="fa fa-bus fa-stack-1x fa-inverse"></i>
            </span>
            <span>Public Transportation</span>
          </li>
          <li className="key-item">
            <span class="fa-stack">
              <font class="ico-font-e">e</font>
              
            </span>
            <span>Accepts Electronic Prescriptions</span>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Key;