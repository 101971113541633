import React, { useState } from 'react';
import Map from './Map';
import Key from './Key';
import Modal from './Modal';
import "./results.scss"


const Icon = ({ className }) => <div className={`icon ${className}`} />;

const Results = ({ loading, data, handleNext, handleFirst, handlePrev, page }) => {

    const [showModal, setShowModal] = useState(false);
    const [extraData, setExtraData] = useState({})

    function handleClick (e){
        
        const extraData = {
            npi: e.target.parentElement.parentElement.querySelectorAll(".row")[1].querySelector('p').innerText.split(':')[1].trim(),
            providerName: e.target.parentElement.parentElement.querySelector('.provider-header').innerText,
            address: e.target.parentElement.parentElement.querySelectorAll('.row')[1].querySelectorAll('p')[1].innerText.split(':')[1].trim()
        }
       setShowModal(true)
       setExtraData(extraData)
    }

    function onClose (){
       setShowModal(false)
    }
   
    return (
        
        <>
        
        
    {
     !loading ?
       <div className='results-container'>
            <script src='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css'></script>
            <script src='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/js/all.min.js'></script>
            <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/fontawesome.min.css" rel="stylesheet" />
            <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/brands.min.css" rel="stylesheet" />
            <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/solid.min.css" rel="stylesheet" />
            {/* <button className='provider-button'>Print Results</button> */}
            {/* <Map /> */}
            <Key />
            {
                data.map((result) => (
                    <div class="result">
                        <div className="header">
                            <div class="provider-header">
                                {result.full_name}
                            </div>
                            <div className="key-map">

                                <div className="new-patients">
                                    {result.accepting_new_patients ? <span class="fa-stack">
                                        <font class="ico-font-e"></font>
                                        <i class="fa fa-bus fa-stack-1x fa-inverse"></i>
                                    </span> : <></>}
                                </div>
                                <div className="ada-standards">
                                    {result.ada_plus ? <span class="fa-stack fida_ico">
                                        <font id='ada' class="ico-font">ADA+</font>
                                        <i class="fa fa-square fa-stack-2x"></i>
                                    </span> : <></>}
                                </div>
                                <div className="cultural-competance">
                                    {result.cultural_competence ? <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="fa fa-globe fa-stack-1x fa-inverse"></i>
                                    </span> : <></>}
                                </div>
                                <div className="ninety-day">
                                    {result.choice90 ? <span class="fa-stack fida_ico">
                                        <font class="ico-font-90">90</font>
                                        <i class="fa fa-square fa-stack-2x"></i>
                                    </span> : <></>}
                                </div>
                                <div className="medicade">
                                    {result.medicaid_id ? <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="fa fa-house-medical-circle-check fa-stack-1x fa-inverse"></i>
                                    </span> : <></>}
                                </div>
                                <div className="accessible">
                                    {result.accessible ? <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="fa fa-wheelchair fa-stack-1x fa-inverse"></i>
                                    </span> : <></>}
                                </div>
                                <div className="certified">
                                    {result.board_certified_specialty ? <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="fa fa-wheelchair fa-stack-1x fa-inverse"></i>
                                    </span> : <></>}
                                </div>
                                <div className="transportation">
                                    {result.public_transportation ? <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="fa fa-wheelchair fa-stack-1x fa-inverse"></i>
                                    </span> : <></>}
                                </div>
                                <div className="electronic">
                                    {result.erx ? <span class="fa-stack">
                                        <i class="fa fa-square fa-stack-2x"></i>
                                        <i class="fa fa-wheelchair fa-stack-1x fa-inverse"></i>
                                    </span> : <></>}
                                </div>

                            </div>
                        </div>
                        <div class="provider-details">
                            <div className="row">
                              {result.category &&  <p>Type: {result.category}</p>}
                              {result.specialty && <p>Specialties: {result.specialty}</p>}
                              {result.office_hours && <p>Days and Hours: {result.office_hours}</p>}
                              {result.language && <p>Languages: {result.language}</p>}
                              {result.special_experience && <p>Special Experience(s): {result.special_experience}</p>}
                            </div>
                            <div className="row">
                              {result.npi && <p>NPI: {result.npi}</p>}
                              {result.street_address && <p>Address: {result.street_address}</p>}
                              {result.city && <p>City: {result.city}</p>}
                              {result.state &&<p>State: {result.state}</p>}
                              {result.zip_code && <p>ZIP: {result.zip_code}</p>}
                              {result.phone &&<p>Phone: {result.phone}</p>}
                            </div>
                            
                        </div>
                        <div class="provider-actions">
                            <a  href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(result.street_address)}`}
                                target="_blank" class="provider-button">Get Directions</a>
                            <button onClick={handleClick} class="provider-button">Report Inaccurate Information</button>
                            <Modal onClose={onClose} show={showModal} extraData={extraData}/>
                        </div>
                    </div>
                ))

            }
            <div className="buttonContainer">
            <button onClick={handleNext}>Next</button>
             
             {  
                page !==1 && 
                <>
                <button onClick={handleFirst}>First</button>
                <button onClick={handlePrev}>Previous</button>
                </>
             }
            </div>
             
             
        </div> : loading ?
            <h3>Loading</h3>
        : 
            <div>No Results</div>
        }
        </>
            
    );
            
};

export default Results;