import React, { useState, useEffect } from 'react';
import './Modal.scss'; // Make sure to create an SCSS file for styling the modal

const Modal = ({ show, onClose, extraData }) => {
  const [flip, setFlip] = useState(false)
  const [data, setData] = useState({
    radioSelection: "",
    details: "",
    phoneNumber: "",
    email: "",
    to: "NDM@centersplan.com",
    cc: "NDM@centersplan.com",
    extraData: extraData
  });


  
  function postData() {
    setFlip(true)
    data.extraData = extraData
  
    // Use fetch API to send a POST request to the specified URL with the data
    fetch('http://ec2-3-83-231-87.compute-1.amazonaws.com:3001/email', {
      method: 'POST', // Specify the method
      headers: {
        'Content-Type': 'application/json', // Set the content type header
      },
      body: JSON.stringify(data), // Convert the JavaScript object to a JSON string
    })
    .then(response => response.json()) // Parse the JSON response
    .then(data => {
      console.log('Success:', data); // Handle success
    })
    .catch((error) => {
      console.error('Error:', error); // Handle errors
    });
  }
  
  useEffect(() => {
    setFlip(false)
    // Function to close the modal if clicked outside of it
    function handleClickOutside(event) {
      const modal = document.querySelector('.modal');

      if (event.target.className === 'modal') {
        onClose();
      }
    }

    // Add event listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener when the component unmounts
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]); // Ensure the effect re-binds if onClose changes

  if (!show) {
    return null;
  }



  const handleDetailsChange = (e) =>{
    setData(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  }



  const handleChange = (e) => {
    const { name, value } = e.target;
    
    setData(prevState => ({
      ...prevState,
      ['radioSelection']: e.target.parentElement.innerText
    }));
  };



  return (<>
   { !flip ? 
   <div className="modal">
      <div className="modal-content">
        <span className="close-button" onClick={onClose}>&times;</span>
        <div className="top">
          <h1>Which information is inaccurate?</h1>
          <label onChange={handleChange} className='radio-input'>
            <input type="radio" name="option" value="option1" onclick="hideDiv4(1)" required="" />
            THE PROVIDER IS NO LONGER AT THIS LOCATION
          </label>
          <label onChange={handleChange}  className='radio-input'>
            <input type="radio" name="option" value="option1" onclick="hideDiv4(1)" required="" />
            THE PROVIDER CLAIMS THAT THEY DO NOT ACCEPT CENTERS PLAN.
          </label>
          <label onChange={handleChange}  className='radio-input'>
            <input type="radio" name="option" value="option1" onclick="hideDiv4(1)" required="" />
            THE PHONE NUMBER IS INCORRECT
          </label>
          <label onChange={handleChange}  className='radio-input'>
            <input type="radio" name="option" value="option1" onclick="hideDiv4(1)" required="" />
            THE ADDRESS IS INCORRECT
          </label>
          <label onChange={handleChange}  className='radio-input'>
            <input type="radio" name="option" value="option1" onclick="hideDiv4(1)" required="" />
            OTHER
          </label>
          <input onChange={handleDetailsChange} name='details' type='text' placeholder='PLEASE SHARE DETAILS...' />
        </div>
        <div className="bottom">
          <span>Please enter your contact information so that we can contact you regarding this inaccuracy. </span>
          <div className="inputs">
            <input name='email' onChange={handleDetailsChange} type='email' placeholder='EMAIL' />
            <input name='phoneNumber' onChange={handleDetailsChange} type='text' placeholder='PHONE NUMBER' />
          </div>
          <button onClick={postData} className='submit-button'>Submit</button>
        </div>
      </div>
    </div> :
    <div className="modal">
      <div className="modal-content">
        <h1>Message sent.</h1>
      </div>
    </div>
    }
    </>
  );
};

export default Modal;
